import React from "react";
import Collapsible from "react-collapsible";
import styles from "./CollapsibleSection.module.css";

const CollapsibleSection = ({ info }) => {
  return (
    <>
      <div className={styles.collapsibleSection}>
        {info.map((entry, key) => {
          return (
            <Collapsible
              trigger={entry.title}
              triggerTagName="div"
              className={styles.collapsible}
              openedClassName={styles.collapsibleOpen}
              triggerClassName={styles.collapsibleTrigger}
              triggerOpenedClassName={styles.collapsibleTriggerOpen}
              contentOuterClassName={styles.collapsibleOuter}
              contentInnerClassName={styles.collapsibleInner}
              lazyRender={true}
              key={key}
            >
              {Array.isArray(entry.text) ? (
                <ul>
                  {entry.text.map((point, key2) => {
                    return <li key={key2}>{point}</li>;
                  })}
                </ul>
              ) : (
                <p>{entry.text}</p>
              )}
            </Collapsible>
          );
        })}
      </div>
    </>
  );
};

export default CollapsibleSection;
