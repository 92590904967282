import React from "react";
import CocktailSection from "../components/CocktailSection";
import Cocktail from "./cocktail-content";
import PageIntro from "../components/PageIntro";
import PageIntroContent from "./pageintro-content";

const CocktailPage = () => (
  <>
    <PageIntro pageName="cocktails" info={PageIntroContent} />
    <CocktailSection info={Cocktail} />
  </>
);

export default CocktailPage;
