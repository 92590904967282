import React from "react";
import Collapsible from "react-collapsible";
import styles from "./ArtSection.module.css";

const ArtSection = ({ info }) => {
  return (
    <>
      <div className={styles.artSection}>
        {info.map((collection, key) => {
          return (
            <Collapsible
              trigger={collection.collection}
              triggerTagName="div"
              className={styles.collapsible}
              openedClassName={styles.collapsibleOpen}
              triggerClassName={styles.collapsibleTrigger}
              triggerOpenedClassName={styles.collapsibleTriggerOpen}
              contentOuterClassName={styles.collapsibleOuter}
              contentInnerClassName={styles.collapsibleInner}
              lazyRender={true}
              key={key}
            >
              <p>{collection.collection_desc}</p>
              <div className={styles.artCollection}>
                {collection.items.map((item, key2) => {
                  return (
                    <div className={styles.artItem} key={key2}>
                      <img className={styles.artImg} src={item.pic} alt={item.title}></img>
                      <h3>{item.title}</h3>
                      <p>
                        <strong>Year: </strong>
                        {item.year}
                      </p>
                      <p>
                        <strong>Location: </strong>
                        {item.location}
                      </p>
                    </div>
                  );
                })}
              </div>
            </Collapsible>
          );
        })}
      </div>
    </>
  );
};

export default ArtSection;
