const Projects = [
  {
    title: "SHIELD TV Field & Production Data Tracking and Visualizations",
    pic: "./images/shield_tv.jpg",
    link: "https://www.nvidia.com/en-us/shield/",
    time: "Sept 2017 - Ongoing",
    industry: "",
    content: `Since my internship at NVIDIA I've headed the collection, processing and visualization of engineering data coming from the many SHIELD TV devices in the field.
    Like all consumer electronic devices, SHIELD TV can report crashes, errors and other events. I have lead the effort of identifying what to collect, defining the best collection medium and visualizing the data.
    This data is used to proactively identify issues in the field, prioritize resource assignment and evaluate feature success.
    From the success of the field data collection, I expanded this to factory data to bring clarity to the outcome of each manufacturing step.
    This enabled us to identify specific stations that were doing faulty assemblies and drifts in testing result to proactively solve issues at the factories before they become field problems.
     `,
    achievements: [
      "Grew the initiative from a few to more than 80 engineering visualizations, serving HW, SW and Marketing teams",
      "Spearheaded a transition to a data-driven mindset for feature prioritization",
      "Brought transparency to the manufacturing process, identifying issues that improved yield by a few points",
    ],
  },
  {
    title: "SHIELD TV Internal Beta Program",
    pic: "./images/shield_tv.jpg",
    link: "https://www.nvidia.com/en-us/shield/",
    time: "Sept 2017 - Ongoing",
    industry: "",
    content: `Also since my internship, I lead the internal beta program for SHIELD devices. I selectively identified up to 100 NVIDIA employee testers, provided them unreleased HW and SW, handled all communication and collected bug reports and feedback from them.
    This program enabled to find bugs that extensive QA had missed and test numerous compatibility scenarios that would be hard to cover exclusively in engineering and development, with little cost to the company given internal enthusiasts are free.
     `,
    achievements: [
      "Improved the quality of SHIELD products by running an initiative that identified issues that months of QA had missed",
      "Continuously improved a selected beta user base by rating participants based on interactions",
      "Handled a fairly large scale feedback system",
    ],
  },
];

export default Projects;
