import React from "react";

const NotFoundPage = () => (
  <>
    <h1>Sorry, this page either never existed, or does not exist any more</h1>
    <h2>
      Please visit the home page where you might find the path to what you were
      looking for
    </h2>
  </>
);

export default NotFoundPage;
