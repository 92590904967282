import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TiThMenuOutline } from "react-icons/ti";
import { RiHome2Line, RiToolsLine } from "react-icons/ri";
import { GoPaintcan } from "react-icons/go";
import { FaCocktail } from "react-icons/fa";
import styles from "./navbar.module.css";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <nav className={styles.navBar}>
        <ul className={toggle ? styles.showLinks : styles.links}>
          <li>
            <Link
              to="/"
              onClick={() => {
                setToggle(false);
              }}
            >
              <RiHome2Line /> Home
            </Link>
          </li>
          <li>
            <Link
              to="/work"
              onClick={() => {
                setToggle(false);
              }}
            >
              <RiToolsLine /> Work
            </Link>
          </li>
          <li>
            <Link
              to="/art"
              onClick={() => {
                setToggle(false);
              }}
            >
              <GoPaintcan />
              Art
            </Link>
          </li>
          <li>
            <Link
              to="/cocktails"
              onClick={() => {
                setToggle(false);
              }}
            >
              <FaCocktail /> Cocktails
            </Link>
          </li>
        </ul>
        <button onClick={() => setToggle(!toggle)}>
          <TiThMenuOutline />
        </button>
      </nav>
    </>
  );
};

export default NavBar;
