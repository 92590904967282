import React from "react";
import Collapsible from "react-collapsible";
import styles from "./CocktailSection.module.css";

const CocktailSection = ({ info }) => {
  return (
    <>
      <div className={styles.cocktailSection}>
        {info.map((category, key) => {
          return (
            <Collapsible
              trigger={category.category}
              triggerTagName="div"
              className={styles.collapsible}
              openedClassName={styles.collapsibleOpen}
              triggerClassName={styles.collapsibleTrigger}
              triggerOpenedClassName={styles.collapsibleTriggerOpen}
              contentOuterClassName={styles.collapsibleOuter}
              contentInnerClassName={styles.collapsibleInner}
              lazyRender={true}
              key={key}
            >
              <p>{category.category_desc}</p>
              <div className={styles.cocktailCategory}>
                {category.items.map((item, key2) => {
                  return (
                    /*Only displays picture if available*/
                    <div class={styles.cocktailItem} key={key2}>
                      {item.pic !== "" ? (
                        <img className={styles.cocktailImg} src={item.pic} alt={item.name}></img>
                      ) : null}
                      <h3>{item.name}</h3>
                      <p>
                        <strong>Components: </strong>
                      </p>
                      <ul className={styles.cocktailComponentList}>
                        {item.components.map((ingredient, key3) => {
                          return <li key={key3}>{ingredient}</li>;
                        })}
                      </ul>
                      <p>
                        <strong>Recipe: </strong>
                        {item.recipe}
                      </p>
                      <p>
                        <strong>Serving Glass: </strong>
                        {item.glass}
                      </p>
                    </div>
                  );
                })}
              </div>
            </Collapsible>
          );
        })}
      </div>
    </>
  );
};

export default CocktailSection;
