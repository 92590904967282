const Cocktail = [
  {
    category: "Specials",
    category_desc: `I consider these to be the best offering from my bar.
    This is a good place where to start`,
    items: [
      {
        name: "Earl Grey Vodka Sour",
        pic: "./images/coming_soon.PNG",
        components: [
          "Vodka (Absolut Elyx)",
          "A Splash of Bergamot Liqueur (Italicus)",
          "Bergamot Bitters",
          "One Lime",
          "A Few Spoons of Sugar",
        ],
        recipe: `Squeeze the lime fully, mix the juice with the sugar, the vodka, a teaspoon of bergamot liqueur and at least 8 dashes of bergamot bitters in a shaker with ice.
        Shake for a while to melt some ice, then strain the drink  from the ice. Pour in the glass and serve while cold.
        Keep in mind the key to enjoying this drink is to find your balance of the ingredients. There's no recipe which works for everyone. Most of the drink should be the vodka, but the other elements are what define it.
        If you find it in general too sweet, you can omit the bergamot liqueur and opt for more bitters.
        Have fun figuring it out!`,
        glass: "Single Rocks Glass",
      },
      {
        name: "Smoked Vintage Martini",
        pic: "./images/coming_soon.PNG",
        components: ["1 part Gin", "1 part White Vermouth", "1 part Madeira (preferably Bual)"],
        recipe: `Mix the three ingredients in a shaker with ice cubes, shake plentifully, then strain the drink from the ice. Pour in the serving glass.
        Finish with Cuban cigar smoke for 5 to 10 minutes before serving.
        Try this drink with both sweet and dry vermouths. If you want to experiment more, you can try with other types of Madeira, though I prefer Buals for drinks.`,
        glass: "Single Rocks Glass (Martini glasses are hard to smoke)",
      },
    ],
  },
  {
    category: "Originals",
    category_desc: `These are what I consider to be my original creations, though a lot of the inspiration comes from the countless amazing bars I've had the luck to visit.
    Enjoy at your own discretion`,
    items: [
      {
        name: "Ocean View",
        pic: "./images/cocktails/old_ocean_view.jpg",
        components: ["1 part Blended Scotch", "1 part LBV Port", "1 part Bergamot Liqueur"],
        recipe: `Stir the ingredients in a shaker glass with ice cubes. Strain to remove the ice and pour in the serving glass
        Finish with Cuban cigar smoke for 5 min and sprinkle with golden flakes`,
        glass: "Single Rocks Glass",
      },
      {
        name: "Sweet Nights",
        pic: "./images/cocktails/sweet_nights.jpg",
        components: [
          "1 part Bourbon",
          "1 part Red Vermouth (Carpano Rosso)",
          "1 part Raspberry Liqueur (Chambord)",
          "Chocolate Bitters",
          "(For Syringe) Elderflower Liqueur (St. Germain)",
          "(For Syringe) Orange Bitters",
        ],
        recipe: `Shake the bourbon, red vermouth and raspberry liqueur in a shaker with ice. Strain to remove the ice and pour in the serving glass.
        Add a few drops of chocolate bitters to the glass right before serving.
        Separately, prepare a Syringe with the Elderflower liqueur and a few drops of orange bitters. Serve that separately placed in a shot glass with shredded ice to keep cold.
        Drinker can add the syringe content to the cocktail to add a more floral note, or just sip that at the end to cleanse the palate`,
        glass: "Margarita Glass with Syringe in separate Shot Glass",
      },
      {
        name: "Smoky Sunset",
        pic: "./images/cocktails/smoky_sunset.jpg",
        components: ["1 part Campari", "1 part Mezcal", "2 parts Grapefruit Soda"],
        recipe: `Mix the Campari and Mezcal in a glass with ice cubes. Top off with the grapefruit soda.
        Add a straw. Best to let the drinker mix it.`,
        glass: "Highball or Jar Glass",
      },
      {
        name: "Pharmacy",
        pic: "./images/cocktails/pharmacy.jpg",
        components: ["1 part Gin", "1 part Amaro di Angostura", "1 part Grapefruit Soda"],
        recipe: `Mix the Gin and Amaro in a glass with a big single ice cube (square or spherical). Top off with the grapefruit soda.
        This drink can also be tried with different amaros`,
        glass: "Single Rocks Glass",
      },
      {
        name: "Nutty Night",
        pic: "./images/cocktails/nutty_night.jpg",
        components: [
          "2 parts Bourbon",
          "1 part Frangelico",
          "2 parts Almond Milk",
          "Chocolate Bitters",
        ],
        recipe: `Shake the Bourbon, Frangelico and almond milk in a shaker with ice. Strain to remove the ice and pour in the serving glass.
        Add a few drops of chocolate bitters to the glass. Serve with a sprinkle of cinnamon and chocolate shavings.`,
        glass: "Single Rocks Glass",
      },
      {
        name: "Zen Aperitif",
        pic: "./images/cocktails/zen_aperitif.jpg",
        components: [
          "1 part Bitter (Contratto)",
          "1 part Yuzu Ginger Aperitif (Haus)",
          "1 part Mediterranean Tonic (Fever Tree)",
        ],
        recipe: `Pour the Bitter and the Yuzu Ginger Aperitif in the serving glass over ice. Then top with the tonic water.
        Serve as is with a straw, then stir before drinking. Top with a lime or other citrus slice `,
        glass: "Highball or Jar Glass",
      },
    ],
  },
  {
    category: "Interpretations of Classics",
    category_desc: `These are my interesting twists to some known classics, explored more because of boredom rather than because of shortcomings in the original recipes.
    I hope to not offend anyone with the sometimes far fetched modifications.
    Enjoy if you're looking for something new but still familiar.`,
    items: [
      {
        name: "Madeira Manhattan",
        pic: "./images/cocktails/madeira_manhattan.jpg",
        components: [
          "1 part Bourbon",
          "1 part Red Vermouth (Carpano Rosso)",
          "1 part Madeira Bual",
          "Chocolate Bitters to finish",
        ],
        recipe: `Stir the ingredients except the bitters in a shaker glass with ice cubes. Strain to remove the ice and pour in the serving glass
        Finish with Cuban cigar smoke for 5 min, then add chocolate bitters and mix right before serving`,
        glass: "Single Rocks Glass",
      },
      {
        name: "Rose Gold Martini",
        pic: "./images/cocktails/rose_gold_martini.jpg",
        components: [
          "2 parts Rose Dry Gin (Glendalough Rose Irish Gin)",
          "1 part Dry White Vermouth",
          "Gold flakes to finish",
        ],
        recipe: `Shake the gin and the vermouth in a shaker glass with ice. Strain to remove the ice and pour in the serving glass.
        Finish with the gold flakes on top before serving.
        For this recipe it is suggested to use a dry rose gin. There are sweet versions on the market and they could be an interesting alternative.`,
        glass: "Martini Glass",
      },
      {
        name: "Coastal Gin Tonic",
        pic: "./images/cocktails/coastal_gin_tonic.jpg",
        components: [
          "3 parts Gin (Gray Whale California Gin)",
          "1 part Wild Sardinia Vermouth",
          "3 parts tonic",
          "Rosemary for garnish",
        ],
        recipe: `Pour the gin, the vermouth and the tonic in a glass with ice.
        Serve with a bamboo straw and garnish with a rosemary sprig.
        Other gins and vermouths can be used, better to stay on the aromatic and herbal side to give it the coastal flavor.`,
        glass: "Highball or Jar Glass",
      },
    ],
  },
];

export default Cocktail;
