import React from "react";
import styles from "./Heading.module.css";

const Heading = () => (
  <>
    <header className={styles.banner}>
      <div className={styles.headText}>
        <h1>Sebastiano's Online Home</h1>
      </div>
    </header>
  </>
);
export default Heading;
