import React from "react";
import SkillsSection from "../components/SkillsSection";
import ProductSection from "../components/ProductSection";
import PageIntro from "../components/PageIntro";
import PageIntroContent from "./pageintro-content";
import Skills from "./skill-content";
import Products from "./product-content";
import Projects from "./project-content";
import ExtButton2 from "../components/ExtButton2";
import { FaFilePdf } from "react-icons/fa";

const WorkPage = () => (
  <>
    <PageIntro pageName="work" info={PageIntroContent} />
    <div id="CV_link">
      <h3>
        Link to my CV:{"    "}
        <ExtButton2
          to="https://www.dropbox.com/s/1in10lrmg3bh5fg/Sebastiano_Bea_CV_2022.pdf?dl=0"
          text={<FaFilePdf />}
        />
      </h3>
    </div>
    <SkillsSection info={Skills} />
    <ProductSection info={Products} title="Some of the major products I've worked on..." />
    <ProductSection info={Projects} title="...and some of the side projects" />
  </>
);

export default WorkPage;
