const Content = [
  {
    title: "About this website (and why)",
    text: `I've always wanted to create an online showroom for my art and cocktails. I had even taken a web programming class at Stanford during my last spring with the idea of eventually converting the knowledge gained there to a website. Those intentions did not go too far. It is somehow never the perfect time to start a website.
Then COVID came around and I decided that creating this website would be my shelter at home project. There are many options to effortlessly build a website, however I wanted to use this chance to learn something meaningful. Hence I elected to develop the website from scratch.
I focused on learning and using React, given the many times I had heard it associated with (mostly through the Software Engineering Daily podcast) many successful apps and websites that I like.
I tried to minimize the use of external components, to learn as much as I can from the ground up. A few LinkedIn learning courses later, here it is.
I started off with create-react-app, then all that you see is built with the React, Component and useState from the react library; Router, Route, Switch and Link  from React Router DOM. The only external component I used is "Collapsible" from React Collapsible. Finally the icons I leveraged from React Icons.
The CSS is all home made (yes I know… I've got more work to do) and leverages css modules and css variables to control the color scheme on the whole website.
The website is deployed through Vercel.
I'm always welcoming any feedback you might have and any tips to improve this! `,
  },
  {
    title: "Coming Soon",
    text: [
      "Music Section: to list my favorite pieces and to publish my music (that's the next personal project)",
      "Cocktail Bar ranking: I've gone to quite a few of them around the world. Time to jot some thoughts down",
      "Improve the cocktail & paintings pictures",
      "Add website architecture documentation. So I can get more feedback from the community",
    ],
  },
];

export default Content;
