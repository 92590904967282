import React from "react";
import styles from "./PageIntro.module.css";

const PageIntro = ({ pageName, info }) => {
  const content = info.find((page) => page.id === pageName);

  /*renderImg controls the render of the picture*/
  let renderImg = false;
  if (content.pic !== "") {
    renderImg = true;
  }

  return (
    <>
      <section className={styles.introSection}>
        {renderImg && <img src={content.pic} className={styles.introImg} alt={content.id}></img>}
        <div className={styles.textSection}>
          <h1>{content.title}</h1>
          <p className={styles.introSub}>{content.sub}</p>
        </div>
      </section>
    </>
  );
};

export default PageIntro;
