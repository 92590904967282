import React from "react";
import Heading from "../components/Heading";
import PageIntro from "../components/PageIntro";
import PageIntroContent from "./pageintro-content";
import ExtButton2 from "../components/ExtButton2";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import Content from "./homepage-content";
import CollapsibleSection from "../components/CollapsibleSection";

const HomePage = () => (
  <>
    <Heading />
    <PageIntro pageName="homepage" info={PageIntroContent} />
    <div id="social">
      <h2>Connect with me on my socials:</h2>
      <h2>
        <ExtButton2 to="https://linkedin.com/in/sebastianobea" text={<FaLinkedinIn />} />
        {"     "}
        <ExtButton2 to="https://twitter.com/Seba_Bea" text={<FaTwitter />} />
      </h2>
    </div>
    <CollapsibleSection info={Content} />
  </>
);
export default HomePage;
