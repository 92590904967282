import React from "react";
import styles from "./SkillsSection.module.css";

const SkillsSection = ({ info }) => {
  return (
    <>
      <div className={styles.skillSection}>
        {info.map((cat, key) => {
          return (
            <div className={styles.skillCat} key={key}>
              <h3>{cat.category}</h3>
              <ul>
                {cat.skills.map((skill, key2) => {
                  return <li key={key2}>{skill}</li>;
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SkillsSection;
