const Skills = [
  {
    category: "Product / Program Management",
    skills: ["Requirement Documents", "Project Schedules", "Team Documentation", "Bug Tracking"],
  },
  {
    category: "Tools (Used Frequently)",
    skills: ["SQL", "Python", "Splunk", "Tableau", "HTML / CSS / React", "Confluence"],
  },
  {
    category: "HW Operations",
    skills: [
      "Design for Manufacturing (DFM)",
      "Product Lifecycle Management (PLM - Windchill)",
      "Supplier / Partner Search, Management & Negotiation",
    ],
  },
  {
    category: "Design Thinking",
    skills: ["User Personas", "User Experience Map / Journey Map", "User Centered Design"],
  },
  {
    category: "Tools (Used them in the past)",
    skills: ["R", "C++", "C"],
  },
];

export default Skills;
