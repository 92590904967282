import React from "react";
import styles from "./ProductSection.module.css";

const ProductSection = ({ info, title }) => {
  return (
    <>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.productSection}>
        {info.map((product, key) => {
          return (
            <div className={styles.productItem} key={key}>
              <div className={styles.productLeftZone}>
                <img className={styles.productImg} src={product.pic} alt={product.title}></img>
              </div>
              <div className={styles.productContent}>
                <h2>
                  <a
                    className={styles.productLink}
                    href={product.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {product.title}
                  </a>
                </h2>
                {product.time === "" ? null : (
                  <p>
                    <strong>Timeframe: </strong>
                    {product.time}
                  </p>
                )}
                {product.industry === "" ? null : (
                  <p>
                    <strong>Industry: </strong>
                    {product.industry}
                  </p>
                )}
                <p>{product.content}</p>
                <p>
                  <strong>Main Achievements and Learnings</strong>
                </p>
                <ul>
                  {product.achievements.map((point, key2) => {
                    return <li key={key2}>{point}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProductSection;
