const PageIntroContent = [
  {
    id: "homepage",
    title: "Welcome to my website :)",
    pic: "",
    sub: `This is where you can find out more about my passions and what fuels me.
    Read below why I thought about doing this website, or read on in the further sections about my art, cocktails or professional achievements.
    There's also more sections I will add as time permits, more on that in the section below!`,
  },
  {
    id: "work",
    title: "Work: what occupies most of my time and thoughts",
    pic: "./images/intro/workIntro.jpg",
    sub: `It might seem strange considering all the other pages of this website, however this is where I spend most of my time and thoughts each day.
    Technology accelerates all change, and there's nothing more exciting that waking up each day to a different (and hopefully better) world.
    I like to be in very hard working and competitive teams. If you think the same way, I'm looking forward to working with you in the future!
    Sadly no progress comes from sitting back and chilling (but some great suggestions to that in the cocktails section).
    Here are some of the projects I've worked on and skills I've honed during the years. Reach out if you have any feedback or suggestions on those.
    Nothing is perfect, so always looking for tips on how to improve.`,
  },
  {
    id: "art",
    title: "Art: the mirror to one's soul",
    pic: "./images/intro/artIntro.jpg",
    sub: `Everyone is secretly an artist. Here are my attempts to uncover that side of me.
    As you'll see, I have a deep attraction to abstract art and shapes.
    It's the art of the mind. Our imagination can grasp so much from simple forms and geometries.
    It's the art of the people. No skill required, everyone can express themselves through abstract art.
    It can be sustainable art. Suddenly everything that surrounds us can be made into art instead of ending up in the trash.

    Here you can browse my artistic creations. They are divided into collection based on the theme. Click on each one to expand.
    There is a description for each theme. Collections do not share any time association. I go back and forth between styles based on the inspiration of the moment.

    I hope you enjoy them!`,
  },
  {
    id: "cocktails",
    title: "Cocktails: what's on the bar shelf",
    pic: "./images/intro/cocktailIntro.jpg",
    sub: `No good story ever starting with drinking tea... says a song by a Finnish metal band.
    I don't fully agree with that. Tea is a great start to the day. However a cocktail is a way better end.

    It's always a great time to have a drink
    Here you can find some of the recipes I have come to enjoy over the years. Some I may have tried once, some I may prepare often.
    I like to improvise, which is why the best drink is always the one that you think of in the moment.
    A drink can be perfect in one situation, and completely out of place in another; it's always a good idea to have a good baggage of recipes in your mind. You never know which one you're going to need.
    Hopefully this list can help you build out your cocktail handbook, but especially find what you really like, which is ultimately what really matters.
    You don't need a huge number of bottles to start off your home bar, however, needless to say that a wide selection of starting ingredients helps to get the mind juices going (in all senses).
    Lastly, just like cooking, don't forget that the most important ingredient is always love. Don't forget to add a good dose of it to all your preparations.

    I hope you'll forgive the terrible photoes. Clearly photography is not a section of this website and there's a reason for it.

    Enjoy!`,
  },
];

export default PageIntroContent;
