import React from "react";
import styles from "./ExtButton.module.css";

const ExtButton2 = ({ to, text }) => {
  return (
    <>
      <a href={to} target="_blank" rel="noopener noreferrer">
        <button className={styles.buttonLink}>{text}</button>
      </a>
    </>
  );
};
export default ExtButton2;
