const Art = [
  {
    collection: "Nespresso",
    collection_desc: `There are so many objects around us that we use and discard without realizing the beauty they carry.
    Industrial designers spend so much time refining details of many products that make our life better, though we ignore those lost in our consumistic mindset.
    The Nespresso collection deals in the sublimity of a simple and known coffee capsule.
    The reflection of aluminum exterior and the shadows cast by the capsules make these pieces always new and ever inspiring`,
    items: [
      {
        title: "L4C",
        pic: "./images/art/L4C.jpg",
        location: "Gifted to C",
        year: "2013",
      },
      {
        title: "Windows",
        pic: "./images/art/windows.jpg",
        location: "Gifted to my grandparents G & S",
        year: "2014",
      },
    ],
  },
  {
    collection: "Wall Fashion",
    collection_desc: `What if walls could wear clothes? I imagine they would look like this.
    Inspired by the Concetto Spaziale of Fontana, the wall does not only discover it's 3-Dimensionality, but starts living it and embracing it`,
    items: [
      {
        title: "Blue Sunrise",
        pic: "./images/art/blue_sunrise.jpg",
        location: "Artist's Collection",
        year: "2020",
      },
      {
        title: "Wall Fashion",
        pic: "./images/art/wall_fashion.jpg",
        location: "Artist's Collection",
        year: "2019",
      },
      {
        title: "Wall Fashion Black",
        pic: "./images/art/wall_fashion_black.jpg",
        location: "Artist's Collection",
        year: "2020",
      },
      {
        title: "Crossroads",
        pic: "./images/art/crossroads.jpg",
        location: "Artist's Collection",
        year: "2020",
      },
    ],
  },
  {
    collection: "L4",
    collection_desc: `Eternal, unique, personal, intimate
    ...is art.
    There's no better earthly gift than art.
    `,
    items: [
      {
        title: "L4C",
        pic: "./images/art/L4C.jpg",
        location: "Gifted to C",
        year: "2013",
      },
      {
        title: "L4A",
        pic: "./images/art/L4A.jpg",
        location: "Gifted to A",
        year: "2017",
      },
      {
        title: "L4CR",
        pic: "./images/art/L4CR.jpg",
        location: "Gifted to CR",
        year: "2019",
      },
    ],
  },
  {
    collection: "Lines",
    collection_desc: `So simple yet so perfect.
    Even if they barely reach beyond the edge of the painting, they enable us to grasp the epiphany of their infinity.
    Lines and planes translate the rigid and foundational laws of math into something beautiful, fluid and inspiring, uniting two points, one in our reality and one in our imagination.
    `,
    items: [
      {
        title: "Planes",
        pic: "./images/art/planes.jpg",
        location: "Artist's Collection",
        year: "2018",
      },
      {
        title: "Z",
        pic: "./images/art/zenith.jpg",
        location: "Artist's Collection",
        year: "2019",
      },
      {
        title: "Lines in the Sky",
        pic: "./images/art/lines_in_the_sky.jpg",
        location: "Artist's Collection",
        year: "2019",
      },
      {
        title: "Our Lord",
        pic: "./images/art/our_lord.jpg",
        location: "Artist's Collection",
        year: "2018",
      },
      {
        title: "River Sky",
        pic: "./images/art/river_sky.jpg",
        location: "Artist's Collection",
        year: "2020",
      },
      {
        title: "Sun Scorch",
        pic: "./images/art/sun_scorch.jpg",
        location: "Artist's Collection",
        year: "2020",
      },
      {
        title: "Trinity",
        pic: "./images/art/trinity.jpeg",
        location: "Gifted to CTH",
        year: "2016",
      },
      {
        title: "Lines In My Head",
        pic: "./images/art/lines_in_my_head.jpg",
        location: "Artist's Collection",
        year: "2015",
      },
      {
        title: "The Verge",
        pic: "./images/art/the_verge.jpeg",
        location: "Artist's Collection",
        year: "2016",
      },
      {
        title: "Cosmic Rays",
        pic: "./images/art/cosmic_rays.jpg",
        location: "Artist's Collection",
        year: "2016",
      },
    ],
  },
  {
    collection: "Other",
    collection_desc: `Experimenting is the soul of exploration and discovery.
    Just like the Nespresso Collection, art can give new life to many objects and materials that are meant to be discarded daily.
    From giving a new life to cardboard, to inventing a new look for old shoes. Nothing ever dies if you look at it the right way`,
    items: [
      {
        title: "Pollock Shoes",
        pic: "./images/art/pollock_shoes.jpg",
        location: "Artist's Collection",
        year: "2017",
      },
      {
        title: "1015 Folsom",
        pic: "./images/art/1015_folsom.jpg",
        location: "Artist's Collection",
        year: "2019",
      },
      {
        title: "Rebirth",
        pic: "./images/art/rebirth.jpg",
        location: "Artist's Collection",
        year: "2020",
      },
      {
        title: "Eat Sleep Rave Repeat",
        pic: "./images/art/eat_sleep_rave_repeat.jpg",
        location: "Artist's Collection",
        year: "2015",
      },
      {
        title: "Study On Cardboard",
        pic: "./images/art/study_on_cardboard.jpg",
        location: "Artist's Collection",
        year: "2015",
      },
    ],
  },
  {
    collection: "Inspiration",
    collection_desc: `Nobody comes from nothing.
    When visiting museums we often say 'I could have done this'
    and so I did.
    There's no better start to learning than trying to walk the steps of those that came before us`,
    items: [
      {
        title: "Pollock Shoes",
        pic: "./images/art/pollock_shoes.jpg",
        location: "Artist's Collection",
        year: "2017",
      },
      {
        title: "Fontana Blu",
        pic: "./images/art/fontana_blu.jpg",
        location: "Artist's Collection",
        year: "2018",
      },
      {
        title: "Fontana Rosso",
        pic: "./images/art/fontana_1.jpg",
        location: "Artist's Collection",
        year: "2016",
      },
      {
        title: "Stanford",
        pic: "./images/art/stanford_1.jpg",
        location: "Artist's Collection",
        year: "2016",
      },
      {
        title: "Forest of Blood",
        pic: "./images/art/forest_of_blood.jpg",
        location: "Artist's Collection",
        year: "2016",
      },
    ],
  },
];

export default Art;
