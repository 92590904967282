const Products = [
  {
    title: "Jetson  Nano 2GB Developer Kit",
    pic: "./images/JetsonNano2GBDevkit.jpg",
    link:
      "https://www.nvidia.com/en-us/autonomous-machines/embedded-systems/jetson-nano/education-projects/",
    time: "April 2020 - October 2020",
    industry: "AI Edge Inference / Education / Robotics",
    content: `Making Nano even more accessible. This is our offering to get students and more makers more Familiar with NVIDIA hardware.
    Starting from Jetson Nano, we conducted feature revision and costed down the platform, also adding out of the box wifi in the process.
    Through continuous use case testing we managed to iterate and keep same SW features compared to Nano with more memory.`,
    achievements: [
      "Coordinated team to deliver product in 6 months from ideation to on shelf",
      "Initiated and evangelized transition in documentation to make it more accessible to new users, changing approach from offline PDF to online HTML guides",
      "Added wifi module in the box one month before launch identifying and onboarding new supplier in record time, while keeping costs low",
    ],
  },
  {
    title: "Jetson Xavier NX Developer Kit and Module",
    pic: "./images/nx-devkit.jpg",
    link: "https://www.nvidia.com/autonomous-machines/embedded-systems/jetson-xavier-nx/",
    time: "Sept 2019 - April 2020",
    industry: "AI Edge Inference / Robotics / Intelligent Video Analytics",
    content: `This is an expansion to the Jetson family,
      taking the performance of the high end in the easily integratable and cheaper form factor of the low end Nano.
      I guided alignment through HW, SW, marketing and operations, from product feature definition to announcement and shipment`,
    achievements: [
      "Expanded the Devkit product in scope with Wi-Fi and power brick in the box, based on customer feedback to previous Jetson products",
      "Maximized reuse of components from previous Jetson families to add flexibility in Supply chain and lower unit cost",
      "Delivered the product in 8 months navigating day-to-day changing environment due to COVID impact",
    ],
  },
  {
    title: "Clara AGX Developer Kit",
    pic: "./images/Clara_AGX_Banner_crop.png",
    link: "https://developer.nvidia.com/clara-agx-devkit",
    time: "Oct 2019 - Ongoing",
    industry: "Healthcare Imaging",
    content: `An all-in-one development platform to process video & data streams of medical devices and
    run AI inference on them in real time in order to provide insights to doctors and surgeons. Includes a GPU,
    a tegra embedded processor and Mellanox networking to dynamically scale processing power from device only to network resources.`,
    achievements: [
      "Educated an Automotive Board Design team to B2C system design principles and user obsession",
      "Resolved tradeoffs between different design teams and managers who were following different processes previously",
      "Completed design by leveraging SW that was being developed elsewhere in the company in order to reduce workload and improve schedule",
    ],
  },
  {
    title: "BFGD: HP Omen Emperium X (the most premium gaming monitor ever)",
    pic: "./images/bfgd.jpg",
    link: "https://www.omen.com/us/en/displays/omen-x-emperium-65.html",
    time: "Feb 2018 - Mar 2019",
    industry: "Gaming",
    content: `The first 65" 4K 144Hz GSYNC gaming monitor ever. With an integrated SHIELD TV. Ultra Premium.
    This was done in partnership with HP, HP's ODM, panel manufacturer and both GSYNC and SHIELD TV teams at NVIDIA.
    I acted as the product owner when I took over the project, driving the vision of quality across all parties involved`,
    achievements: [
      "Managed a delicate partnership between HP, HP's ODM, the panel manufacturer and NVIDIA",
      "Represented the customer interest during the system design process focused on merging features from different teams and components ",
    ],
  },
  {
    title: "Other non-shipped or internal only projects",
    pic: "./black.png",
    link: "",
    time: "Sept 2017 - Ongoing",
    industry: "Gaming and VR",
    content: `Internal systems project for research use and gaming HW product that we decided not to ship`,
    achievements: [
      "Rapidly hardware prototyped (3D printing, mockups, videos) and tested with internal users to drive feature selection and predict product success",
      "Built product story and evangelized to internal stakeholders, until the point where the story broke justifying design halt",
      "Drove engagement with strategic suppliers in trying to do something that no one else had tried before",
    ],
  },
];

export default Products;
