import React from "react";
import ArtSection from "../components/ArtSection";
import PageIntro from "../components/PageIntro";
import PageIntroContent from "./pageintro-content";
import Art from "./art-original-content";

const ArtPage = () => (
  <>
    <PageIntro pageName="art" info={PageIntroContent} />
    <ArtSection info={Art} />
  </>
);

export default ArtPage;
